.nav.tertiary-menu {
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    justify-content: center;

    li {
      font-size: 0.8rem;

      a {
        padding: 0.25rem 0.75rem;
      }
    }
  }
}
