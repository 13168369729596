@import '@italia/../theme/_site-variables.scss';
@import 'variables';
@import '@italia/../theme/site.scss';

.public-ui {
  /*import here your site customization styles*/
  @import 'custom/main';
  @import 'custom/bootstrap-italia/header';
  @import 'custom/bootstrap-italia/headerslim';
  @import 'custom/bootstrap-italia/headercenter';
  @import 'custom/bootstrap-italia/headernavbar';
  @import 'custom/bootstrap-italia/megamenu';
  @import 'custom/bootstrap-italia/navigation';
  @import 'custom/bootstrap-italia/footer';
  @import 'custom/site/header/header-slim/tertiary-menu';
}
@import 'custom/ItaliaTheme/Components/scrollToTop';

@import 'custom/site/cms';
