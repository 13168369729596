$footer-underline-color: #96a7b8;

.it-footer {
  .it-footer-main {
    background-color: $secondary;

    section.border-white.border-top {
      border: none !important;
    }

    .btn-primary {
      @extend .btn-tertiary;
    }

    h4 {
      padding-bottom: 0.5rem;
      border-bottom: 0.5px solid $footer-underline-color;
      margin-right: 0.5em;

      a {
        font-size: 14px;
      }
    }

    .it-brand-wrapper {
      img.icon.logo-emilia {
        max-height: 65px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          height: auto;
          width: 100%;
        }
      }
      .it-brand-text {
        display: none;
      }

      a {
        .icon {
          width: auto;
          height: $header-center-icon-size;
          //margin-right: $header-center-icon-margin;
          margin: 0;
        }
      }
    }

    .it-socials {
      > span {
        display: none;
      }
    }

    .social {
      .list-inline-item:not(:last-child) {
        margin-right: 0.4rem;
      }

      .list-inline-item {
        margin-bottom: 0.5rem;
      }
    }

    .link-list-wrapper {
      ul {
        li {
          a {
            text-decoration-color: $footer-underline-color;
          }
        }
      }
    }
  }
}

.it-footer-small-prints {
  background-color: $secondary;
  font-weight: 400;

  .container {
    border-top: 1px solid $footer-underline-color;

    a {
      text-decoration: underline;
      text-decoration-color: $footer-underline-color;

      &:hover {
        text-decoration: underline;
        text-decoration-color: $white;
      }
    }
  }
}
