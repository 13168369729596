.it-header-slim-wrapper {
  .it-header-slim-wrapper-content {
    // Mobile / Tablet horizontal / small desktop
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      .it-header-slim-right-zone.header-slim-right-zone {
        flex: 1 1 auto;

        // ul.parent-site-menu {
        //   justify-content: center !important;
        //   justify-self: center;

        //   li {
        //     .nav-link {
        //       padding: 0.3rem;
        //     }
        //   }
        // }
      }
    }
  }
}
